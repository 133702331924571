<template>
  <div class="w-100 d-flex flex-column">
    <div class="mt-3 mr-md-3">
      <trame-uploader
        id="document_offre"
        :file_system="'OFFRES'"
        title="Exportez à partir d'un modèle"
        :data="{
        values: {
          title: affaire_libelle,
          affaire: affaire_identifiant,
          month: $moment().format('MMM YYYY'),
          compte: compteDescription,
          client: clientDescription,
          date: this.$moment().format('DD/MM/YYYY'),
          redactor: this.$store.getters['user/data'].name
        },
        tablesSize: 9,
        tables: [],
        images: []}"
      ></trame-uploader>
    </div>

    <base-form class="mt-3 mr-md-3" :updateBtn="false" shadow>
      <base-form-group title="Offre" dense icon="book">
        <div class="w-100">
          <uploader
            v-if="id"
            id="document_affaire_prepa"
            url="/upload/document"
            title="Vos fichiers"
            :file_system="'CRM'"
            :file_field="'offre'"
            :file_key="id"
          ></uploader>
        </div>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import Uploader from "@/components/bases/Uploader.vue";
import TrameUploader from "@/components/application/TrameUploader.vue";

export default {
  components: {
    BaseForm,
    BaseFormGroup,
    Uploader,
    TrameUploader
  },
  data() {
    return {};
  },
  computed: {
    ...mapFields("affaires", [
      "affaire.id",
      "affaire.affaire_libelle",
      "affaire.affaire_identifiant",
      "affaire.affaire_comptes",
      "affaire.affaire_clients"
    ]),
    compteDescription() {
      if (this.affaire_comptes) {
        return this.affaire_comptes.description;
      }
      return "";
    },
    clientDescription() {
      if (this.affaire_clients) {
        return (
          this.affaire_clients.client_nom +
          " " +
          this.affaire_clients.client_prenom
        );
      }
      return "";
    }
  },
  methods: {
    getExportPtc: function() {
      this.$http
        .get("/affaires/" + this.id + "/exports/ptc", {
          responseType: "arraybuffer"
        })
        .then(response => {
          this.loading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "helloWorld.docx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>
